$siteWidth: 1200px;
$mobileWidth: 768px;
$topBarSize: 74px;
$footerSize: 100px;
$borderRadius: 18px;
$tokenRadius: 12px;
$bannerSize: 42px;
$badgeSize: 38px;
$chartHeight: 300px;
$noticeHeight: 46px;
$spacing0: 2px;
$spacing1: 4px;
$spacing2: 8px;
$spacing3: 16px;
$spacing4: 24px;
$spacing5: 32px;
$spacing6: 48px;
$spacing7: 64px;
$white: #fff;
$black: #000;
$base: #070307;
$baseDim: #da6814;
$card: #0f0c0d;
$card70: rgba(15, 12, 13, 0.7);
$mint: #ffd93b;
$purple: #2b233f;
$placeholderBase: #212121;
$placeholderPoint: #424242;
$maximusLight: #facfd8;
$maximusDark: #e41e4d;
$maximusDim: #930b2c;
$statusOk: #58d68d;
$statusWarning: #eb984e;
$statusAlert: #e74c3c;
$grey50: #f4f4f4;
$grey100: #f9fafb;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #c8c8c8;
$grey500: #999999;
$grey600: #666666;
$grey700: #616161;
$grey800: #424242;
$grey900: #262626;
$sushi: rgb(250, 82, 160);
$quick: rgb(0, 116, 217);
