@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1000;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.text-link {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  background-color: inherit;
  text-decoration: underline;
}

.popups-wrapper {
  position: fixed;
  width: 100%;
  max-width: 280px !important;
  top: 64px;
  left: unset;
  right: calc(50vw - 576px);
  z-index: 900;
}

@media (max-width: 768px) {
  .popups-wrapper {
    max-width: unset !important;
    width: calc(100vw - 48px);
    right: 24px;
    left: 24px;
  }
}

.popup {
  position: relative;
  width: 100%;
  display: inline-block;
  background-color: #2b233f;
  padding: 16px;
  border-radius: 18px;
  overflow: hidden;
  right: 100px;
}

.popup > .popup-close {
  position: absolute;
  right: 16px;
  top: 16px;
  color: #fff;
}

.popup > .popup-fader {
  position: absolute;
  height: 2px;
  min-height: 2px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #0066ff;
}

.tx-popup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
}

.tx-popup > .tx-popup-icon {
  margin-right: 16px;
}

.tx-popup > .tx-popup-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  color: #eee;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.tx-popup > .tx-popup-info > .hash {
  color: #eee;
  font-size: 14px;
  font-weight: 700;
}

.top-alert {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 46px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 100;
  color: #fff;
  padding: 0 15px;
  background-color: #d0234a;
}

.top-alert.show {
  height: 46px;
  opacity: 1;
}

.top-alert.hide,
.top-alert.show {
  transition: height 0.5s, opacity 0.5s;
}

.top-alert.hide {
  height: 0;
  opacity: 0;
}

.top-alert .message {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.top-alert .message > .external-icon {
  margin-left: 4px;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #999;
  line-height: 1.4;
  letter-spacing: 0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

div,
input,
span {
  box-sizing: border-box;
}

.app-twitter-embed {
  display: block;
  position: fixed;
  right: 16px;
  bottom: 8px;
}

@media (max-width: calc(768px + 400px)) {
  .app-twitter-embed {
    display: none;
  }
}

.app-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-position: 0 30vh;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    #30250a 0,
    #070307 100%
  );
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tab-token-logo {
  width: 35px;
  height: 35px;
  border-radius: 30px;
}

.clickable {
  cursor: pointer;
}

.suspense {
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.page {
  min-height: 100%;
}
